import React, { useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

// Components
import TextBox from "../components/text/text-box";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  padding: 60px 20px 5px 20px;

  @media (max-width: 768px) {
    padding: 60px 20px 30px 20px;
  }

  @media (max-width: 500px) {
    padding: 46px 10px 30px 10px;
  }
`;

const BasicColumn = styled.div`
  & .split-column {
    & > div {
      width: fit-content;
      column-count: 2;
      column-width: 200px;
      column-gap: 30px;
      margin: 0 0 0 auto;
    }

    padding: 0 20px 0 0;

    order: 1;

    @media (max-width: 1220px) {
      order: 10;

      max-width: 100%;
      display: block;
    }

    @media (max-width: 920px) {
      column-count: unset;
      column-width: unset;

      max-width: 300px;
      width: 100%;

      padding: 0 0 0 40px;
    }

    @media (max-width: 768px) {
      padding: 0;

      & p:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 640px) {
      padding: 0;
    }
  }

  & p:first-of-type {
    margin-top: 0;
  }
`;

const AboutColumn = styled(BasicColumn)`
  width: 100%;
  max-width: 600px;

  margin: 0 0 30px 0;

  // & p {
  //   margin: 0;
  // }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const PrivacyPolicy = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  return (
    <Page>
      <Helmet
        title={`Privacy Policy – Wolfe Hall`}
        meta={[
          {
            name: "og:url",
            content: `https://www.wolfehall.com/privacy-policy/`,
          },
        ]}
      />

      <AboutColumn>
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicPrivacyPolicy.data.text}
        />
      </AboutColumn>
    </Page>
  );
};

export default PrivacyPolicy;

export const pageQuery = graphql`
  query {
    prismicPrivacyPolicy {
      data {
        text {
          html
        }
      }
    }
  }
`;
